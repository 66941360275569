<template>
  <section class="section section-shaped section-lg my-0">
    <div class="shape shape-style-1 bg-gradient-default">
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
    </div>
    <div class="container">
      <h1 class="display-3  text-white">
        F.A.Q. aka ЧАВО
      </h1>
      <card>
        <h6>Q: Где я?</h6>
        <p>A: Глори - это площадка для выкладывания ролевых текстов, любых, по любым мирам, своим или литературным. Единственное ограничение - РПФ (живыми людьми, сохраняя одновременно имена, фото и биографию, играть не надо). Текстовая ролевая игра - это соавторство по ходам, где авторы создают персонажей и вместе плетут историю, по очереди описывая происходящее с перспективы своего персонажа.</p>
        <h6>Q: То есть, это еще один форум для ролёвок?</h6>
        <p>A: Не совсем. На форумах обычно есть социальный компонент и какая-то общая тематика игры. Здесь же фокус именно на игре, поэтому привычных социальных компонентов вроде тем для флуда, фактов о персонажах, соцсетей и т.д. здесь нет.</p>
        <h6>Q: Зачем это?</h6>
        <p>A: Потому что кое-кто заебался искать форум, чтоб можно было просто играть, что хочешь, без драм, бюрократии и странных требований вроде "отписывайтесь раз в неделю, или вас удалят".</p>
        <h6>Q: А правила есть?</h6>
        <p>A: Правила очень простые: относиться с уважением к другим игрокам, не присваивать чужие работы, не клепать секретных твинков, не писать гадости в комментарии.</p>
        <h6>Q: Как часто нужно писать?</h6>
        <p>A: Никаких требований по скорости нет. Пишите раз в час, раз в неделю или раз в год. Или вообще не пишите - это между вами и соигроками. Требований а ля "не заводить нового персонажа, не отыграв за старого" тоже нет.</p>
        <h6>Q: А как много?</h6>
        <p>A: Тоже без ограничений. Хоть по предложению отыгрывайте.</p>
        <h6>Q: Где занимать внешки?</h6>
        <p>A: Нигде. Ставьте на аватарку персонажа кого хотите, хоть красный квадрат Малевича. Дубли внешек не запрещены.</p>
        <h6>Q: И дубли имён не запрещены?</h6>
        <p>A: Технически нет, но на практике два персонажа с одинаковым именем будут одинаково отображаться в списке персонажей, поэтому лучше хоть в букву сделайте различие, чтоб не путаться.</p>
        <h6>Q: А анкеты куда писать?</h6>
        <p>A: Зарегистрируйтесь, залогиньтесь, и на странице "Игрок" будет возможность добавить персонажа. Анкета необязательна. Обязательны только имя и дата рождения.</p>
        <h6>Q: Секретных твинков нельзя, а несекретных можно? Кому говорить?</h6>
        <p>A: Под одним своим логином можно завести сколько угодно персонажей - те же твинки. Говорить никому не надо, главное - не заводите несколько логинов, чтоб не заставлять других игроков гадать, где чей персонаж, и параноить.</p>
        <h6>Q: А где посмотреть комментарии/плюсики/фидбек к постам?</h6>
        <p>A: На странице "Игрок" можно посмотреть, что написали вы и вам. Больше нигде. Кто где что кому ещё написал, не показывается - фидбек остаётся между автором и адресатом. На один пост можно оставлять сколько угодно комментариев.</p>
        <h6>Q: Что такое коллекция?</h6>
        <p>A: Коллекции - это вроде тэгов, которые можно добавлять к эпизодам, чтоб их группировать. Например, все эпизоды с одной и той же парой персонажей, или все эпизоды в одном и том же АУ-мире, или все эпизоды с котиками.</p>
        <h6>Q: Как отправить личное сообщение с предложением поиграть?</h6>
        <p>A: Пока никак. В будущем будет.</p>
        <h6>Q: Что такое "черновик"?</h6>
        <p>A: Черновик эпизода: если есть идея на поиграть, можно оформить её в виде черновика, чтоб не забыть. Или для себя, или для поиска соигроков (тогда нужно в описании указать, что вы их вообще ищете).</p>
        <h6>Q: Почему "Глори"? Почему "санбантая"?</h6>
        <p>A: "Глори" - потому что почему бы и нет (отсылка к "King's Avatar" и тамошней совершенно не текстовой ролевой игре). "Санбантая" - потому что у админа личное, и в память о хорошем человеке, которого забрал ковид.</p>
        <h6>Q: Почему X не работает? И другие не покрытые здесь вопросы</h6>
        <p>A: Потому что сайт всё ещё в процессе разработки. Базовый функционал работает, но вылазят косяки. Админ - так себе программист, если уж честно. Пишите на hali4qa@gmail.com - поправлю или помогу разобраться.</p>
      </card>
      <p />
      <card>
        <h6 class="display-3">
          В работе
        </h6>  
        <ul>
          <li>багфикс: На мобильной версии меню остаётся открытым после выбора пункта (клик в правый верхний угол закрывает его)</li>
          <li>Экспорт эпизода в PDF</li>
          <li>Личные сообщения</li>
          <li>Деактивация/активация персонажа (чтоб не показывался в списке на добавление поста)</li>
          <li>Запоминание выбранного персонажа к посту</li>
          <li>Превью поста перед отправкой</li>
          <li>Настройка "мне можно предлагать играть"</li>
          <li>Настраиваемые оповещения о фидбеке</li>
          <li>Неигровые треды для поиска соигроков и фмобов</li>
          <li>Допиливание дизайна</li>
          <li>Постраничное отображение в списке эпизодов (чтоб не сразу все)</li>
          <li>Структурированные предупреждения по триггерам, по которым можно делать антипоиск</li>
          <li>Рейтинги а ля АО3</li>
          <li>Аутентификация бэкэнда</li>
        </ul>
      </card>
    </div>
  </section>
</template>
<script>

export default {
    name: "FAQPage",
    mounted() {
        document.title = "Glory - F.A.Q.";
    }
};
</script>
<style>
</style>
